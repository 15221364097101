<template>
  <form @submit.prevent="updateAppeal()" action="">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item v-if="validationError" lines="none">
            <ion-label className="ion-text-wrap">
              <ion-text color="danger">
                <p v-for="error of v$.$errors" :key="error.$uid">
                  <ion-icon
                    :ios="alertCircleOutline"
                    :md="alertCircleSharp"
                    size="small"
                    style="vertical-align: middle"
                  ></ion-icon
                  >{{ error.$property }}: {{ error.$message }}
                </p></ion-text
              >
            </ion-label>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.appeal_type_id.$error }">
            <ion-label position="floating">Appeal Type</ion-label>
            <ion-select
              v-model="appeal_type_id"
              :value="appeal_type_id"
              disabled
            >
              <ion-select-option
                v-for="appeal_type of appealTypes"
                :key="appeal_type.id"
                :value="appeal_type.id"
              >
                {{ appeal_type.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.appeal_from_date.$error }">
            <ion-label position="floating">Appeal from</ion-label>
            <ion-datetime type="text" v-model="appeal_from_date"></ion-datetime>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.appeal_to_date.$error }">
            <ion-label position="floating">Appeal upto</ion-label>
            <ion-datetime type="text" v-model="appeal_to_date"></ion-datetime>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.name.$error }">
            <ion-label position="floating">Name</ion-label>
            <ion-input type="text" v-model="name"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.description.$error }">
            <ion-label position="floating">Description</ion-label>
            <ion-textarea rows="10" v-model="description"></ion-textarea>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button type="submit" expand="full" class="ion-margin-vertical">
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonButton,
  IonInput,
  IonTextarea,
  IonDatetime,
  IonText,
  loadingController,
  alertController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import { required, maxLength, integer, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";

export default {
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  emits: ["closeModal"],
  components: {
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonButton,
    IonInput,
    IonTextarea,
    IonDatetime,
    IonText,
  },
  data() {
    return {
      appeal_type_id: null,
      appeal_from_date: this.resource.appeal_from_date,
      appeal_to_date: this.resource.appeal_to_date,
      name: this.resource.name,
      description: this.resource.description,

      validationError: null,

      alertCircleOutline,
      alertCircleSharp,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    appealTypes() {
      return this.$store.getters["attribute/appealTypes"];
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      appeal_type_id: {
        required: helpers.withMessage("is required", required),
        integer: helpers.withMessage("can only be integers", integer),
      },

      appeal_from_date: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
      appeal_to_date: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
        // Custom Validator
        minValue: helpers.withMessage(
          "cannot be less than from date",
          function minValue(val) {
            return new Date(val) >= new Date(this.appeal_from_date);
          }
        ),
      },

      name: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      description: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
    };
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });
      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        console.log(error.request);
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async updateAppeal() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let data = {
        id: this.resource.id,
        appeal_type_id: this.appeal_type_id,
        appeal_from_date: moment(this.appeal_from_date).format("YYYY-MM-DD"),
        appeal_to_date: moment(this.appeal_to_date).format("YYYY-MM-DD"),
        name: this.name,
        description: this.description,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("appeal/updateResource", data);
        spinner.dismiss();
        this.name = null;
        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();

        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );

        alertDialog.present();

        this.password = null;
        this.validationError = null;
      }
    },

    async fetchAppealTypes() {
      await this.$store.dispatch("attribute/appealTypes");
      this.appeal_type_id = this.resource.appeal_type_id;
    },
  },
  created() {
    this.fetchAppealTypes();
  },
};
</script>