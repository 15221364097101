<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-list
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-card>
        <ion-item lines="none" class="ion-no-padding">
          <ion-card-header>
            <ion-card-title> {{ item.appeal_type.name }} </ion-card-title>
          </ion-card-header>
          <ion-button
            @click="
              confirmDelete({
                id: item.id,
                appeal_type: item.appeal_type.name,
              })
            "
            fill="clear"
            slot="end"
            :disabled="disableEdit(item.appeal_state)"
          >
            <ion-icon
              slot="icon-only"
              :ios="trashOutline"
              :md="trashSharp"
              size="small"
              style="color: #eb445a"
            ></ion-icon>
          </ion-button>
        </ion-item>
        <ion-card-header style="padding-top: 0">
          <ion-card-subtitle>
            <ion-text>
              {{ item.name }}
              <br />
              {{ new Date(item.appeal_from_date).toDateString() }} to
              {{ new Date(item.appeal_to_date).toDateString() }}
            </ion-text></ion-card-subtitle
          >
        </ion-card-header>
        <ion-card-content>
          {{ item.description }}
          <br />
          <br />
          Created: {{ new Date(item.created_at).toDateString() }}
          <br />
        </ion-card-content>
        <ion-item>
          <ion-label slot="start"
            >Status: {{ item.appeal_state.name }}</ion-label
          >
          <ion-button
            slot="end"
            @click="setResourceToUpdate(item.id)"
            fill="clear"
            :disabled="disableEdit(item.appeal_state)"
          >
            <ion-icon
              slot="icon-only"
              :ios="createOutline"
              :md="createSharp"
              size="small"
              style="color: grey"
            ></ion-icon>
          </ion-button>
        </ion-item>
      </ion-card>
    </ion-list>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No appeal found</p>
    </ion-list-header>
  </ion-list>
  <ion-modal
    :is-open="updateModalState"
    @didDismiss="setUpdateModalState(false)"
  >
    <base-modal title="Update appeal" @closeModal="setUpdateModalState(false)">
      <update-appeal
        @closeModal="setUpdateModalState(false)"
        :resource="resourceToUpdate"
      ></update-appeal>
    </base-modal>
  </ion-modal>
</template>
<script>
import BaseModal from "../../Base/BaseModal.vue";
import UpdateAppeal from "../Appeal/UpdateAppeal.vue";

import {
  IonList,
  IonListHeader,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle,
  IonText,
  IonCardContent,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonModal,
  alertController,
} from "@ionic/vue";

import {
  trashOutline,
  trashSharp,
  createOutline,
  createSharp,
  documentOutline,
  documentSharp,
} from "ionicons/icons";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardSubtitle,
    IonText,
    IonCardContent,
    IonItem,
    IonLabel,
    IonButton,
    IonIcon,
    IonModal,
    BaseModal,
    UpdateAppeal,
  },
  data() {
    return {
      trashOutline,
      trashSharp,
      createOutline,
      createSharp,
      documentOutline,
      documentSharp,
      updateModalState: false,
      resourceToUpdate: {
        id: null,
        name: null,
      },

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    filter() {
      return this.$store.getters["appeal/segment"];
    },
  },

  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });
      return alert;
    },
    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        console.log(error.request);
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmDelete(resource) {
      const header = `Delete ${resource.appeal_type}?`;
      const message = "This action cannot be undone.";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.delete(resource.id);
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async delete(resourceId) {
      try {
        const payLoad = resourceId;
        await this.$store.dispatch("appeal/deleteResource", payLoad);
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    disableEdit(appealState) {
      if (appealState.name !== "Created") {
        return true;
      } else {
        return false;
      }
    },

    setUpdateModalState(state) {
      this.updateModalState = state;
    },

    setResourceToUpdate(id) {
      const resource = this.resources.data.find(
        (resource) => resource.id === id
      );
      this.resourceToUpdate = resource;
      this.setUpdateModalState(true);
    },
  },
};
</script>